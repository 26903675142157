<template>
  <div class="personal-content">
    <div class="content-left">
      <img src="../../../assets/img/personal-center/avatar.png"/>
      <p>{{ personalObj && personalObj.name }}</p>
    </div>
    <div class="content-right">
      <personal-team-info v-if="status == 0"/>
      <update-password v-if="status==1" @on-back="handleBack"/>
      <update-info v-if="status==2" @on-back="handleBack" @on-success="handleUpdateInfoSuccess"/>
    </div>

    <div class="update-btns" v-if="status== 0">
      <div @click="handleUpdateStatus(1)">
        <img src="../../../assets/img/personal-center/password.png"/>修改密码
      </div>
      <div @click="handleUpdateStatus(2)">
        <img src="../../../assets/img/personal-center/info.png"/>修改信息
      </div>
    </div>
  </div>
</template>

<script>
  import PersonalTeamInfo from './personal-team-info';
  import UpdateInfo from './update-info';
  import UpdatePassword from './update-password';
  import {mapState} from 'vuex';

  export default {
    name: '',
    data() {
      return {
        status: 0
      }
    },
    computed: {
      ...mapState('personalCenter', ['personalObj'])
    },
    components: {
      PersonalTeamInfo,
      UpdateInfo,
      UpdatePassword
    },
    methods: {
      handleUpdateStatus(status = 0) {
        this.status = status
      },
      handleBack() {
        this.status = 0;
      },
      handleUpdateInfoSuccess() {
        this.$emit('on-success')
        this.status = 0;
      }
    }
  }
</script>

<style scoped lang="scss">
  .personal-content {
    width: 1440px;
    height: 855px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    margin: -200px auto 0 auto;
    display: flex;
    position: relative;
    .content-left {
      width: 200px;
      height: 100%;
      border-radius: 10px 0px 0px 10px;
      background: #FAFAFA;
      /*box-sizing: border-box;*/
      padding-top: 45px;
      text-align: center;

      p {
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin: 0;
        margin-top: 15px;
        margin-bottom: 5px;
      }
    }
    .content-right {
      flex: 1;
      width: 1%;
      /*box-sizing: border-box;*/
      padding: 45px;

    }
    .update-btns {
      position: absolute;
      top: 20px;
      right: 50px;
      display: flex;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 36px;
        border-radius: 4px;
        opacity: 1;
        background: #F0F7FF;
        color: #0084FF;
        font-size: 14px;
        cursor: pointer;
      }

      div + div {
        margin-left: 10px;
      }

    }

    /deep/ .common {
      position: relative;
      .title {
        position: relative;
        font-size: 14px;
        color: #104175;
        &::before {
          position: absolute;
          top: 4px;
          left: -10px;
          content: '';
          width: 2px;
          height: 12px;
          background: #104175;
        }
      }
      .go-back {
        position: absolute;
        width: 64px;
        height: 36px;
        border-radius: 4px;
        background: #F0F7FF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #0084FF;
        top: 0;
        right: 0;
        cursor: pointer;
        img {
          margin-right: 3px;
        }
      }
    }
  }
</style>