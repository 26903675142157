<template>
  <div class="update-info common">
    <div class="title">个人信息</div>
    <el-form ref="updateInfoForm"
             :model="model"
             :rules="rules"
             size="small"
             label-width="300px">
      <el-form-item label="姓名：" prop="name" >
        <el-input v-model="model.name" maxlength="20"/>
      </el-form-item>
      <el-form-item label="性别：" prop="gender">
        <el-select v-model="model.gender">
          <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="账号：">
        <el-input v-model="model.userName" disabled/>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model.trim="model.mobile" placeholder="请输入手机号" maxlength="11" disabled></el-input>
      </el-form-item>
      <el-form-item label="身份证：">
        <el-input v-model.trim="model.idCard" maxlength="18" placeholder="请输入身份证" clearable></el-input>
      </el-form-item>
      <el-form-item label="联系邮箱：">
        <el-input v-model.trim="model.email" placeholder="请输入邮箱" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="handleUpdateInfo" v-loading="loading">确定</el-button>
      </el-form-item>
    </el-form>
    <div class="go-back" @click="handleBack">
      <img src="../../../assets/img/personal-center/back.png"/>
      返回
    </div>
  </div>
</template>

<script>
  import genderOptions from "@/const/genderOptions"
  import {mapState} from 'vuex';
  import {mapGetters} from 'vuex'
  import {updatePersonInfo} from '@/api/personal-center'
  import {isIdCard, isEmail, isName} from "@/utils/validate"

  export default {
    name: 'UpdateInfo',
    data() {
      const validateName = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入姓名'))
        } else if (!isName(value)) {
          callback(new Error('请输入正确的姓名'))
        } else {
          callback()
        }
      }
      return {
        genderOptions,
        model: {},
        rules: {
          name: [{required: true, validator: validateName, trigger: "blur"}],
          gender: [{required: true, message: "性别不能为空", trigger: "blur"}],
        },
        loading: false
      }
    },
    computed: {
      ...mapState('personalCenter', ['personalObj']),
      ...mapGetters(['userInfo'])
    },
    components: {},
    created() {
      let {name, gender, userName, mobile, idCard, email} = this.personalObj;
      this.model = {
        name,
        gender,
        userName,
        mobile,
        idCard,
        email
      }
    },
    methods: {
      handleBack() {
        this.$emit("on-back")
      },
      anotherValidate() {
        let {idCard = '', email = ''} = this.model;
        if (idCard && !isIdCard(idCard)) {
          this.$message.error('身份证号码填写错误，请重新确认');
          return false;
        }
        if (email && !isEmail(email)) {
          this.$message.error("请填写正确的邮箱");
          return false;
        }
        return true;
      },
      handleUpdateInfo() {
        this.$refs.updateInfoForm.validate((valid) => {
          if (!valid) return;
          let flag = this.anotherValidate();
          if (!flag) return;
          this.realUpdateInfo();
        })
      },
      async realUpdateInfo() {
        let params = {
          ...this.model,
          id: this.userInfo.id
        }
        this.loading = true;
        try {
          let res = await updatePersonInfo(params);
          this.loading = false;
          if (!res || !res.data) return;
          if (res.data.code != 0) return;
          this.$message.success('修改信息成功');
          this.$emit('on-success')
        } catch {
          this.loading = false
        }

      }
    }
  }
</script>

<style scoped lang="scss">
  .update-info {
    /deep/
    .el-form {
      margin: 50px auto 0 auto;
    }
    /deep/
    .el-input {
      width: 380px;
    }

    /deep/
    .el-button {
      min-width: 380px;
      background: linear-gradient(270deg, #05C0FF 0%, #005EFF 100%);
    }
  }
</style>