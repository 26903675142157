<template>
  <div class="personal-team-info common">
    <div class="title">个人信息</div>
    <el-form
      ref="form"
      size="small"
      :inline="true"
      label-width="130px"
    >
      <el-form-item label="姓名：">
        <span>{{ personalObj.name }}</span>
      </el-form-item>
      <el-form-item label="性别：">
        <span>{{ personalObj.gender | filterSex}}</span>
      </el-form-item>
      <el-form-item label="账号：">
        <span>{{ personalObj.userName || '--'}}</span>
      </el-form-item>
      <el-form-item label="手机号：">
        <span>{{ personalObj.mobile || '--' }}</span>
      </el-form-item>
      <el-form-item label="身份证：">
        <span>{{ personalObj.idCard || '--'}}</span>
      </el-form-item>
      <el-form-item label="邮箱：">
        <span>{{ personalObj.email || '--'}}</span>
      </el-form-item>
    </el-form>
    <div class="team-info">
      <div class="title">团队信息</div>
      <ul v-if="signUpList && signUpList.length">
        <li v-for="(item, index) in signUpList" :key="index">
          <div class="li-top">
            <span>队名：{{ item.groupName }}</span>
            <span>省份：{{ getProvince(item.province)}}</span>
            <span>学校名称：{{ item.orgName }}</span>
            <span>学院/系：{{ item.college }}</span>
            <span>专业名称：{{ item.major }}</span>
          </div>
          <el-table border
                    :data="item.detailList"
                    :header-cell-style="{'background-color':'#F8FBFF'}"
                    style="width: 100%; margin-top: 10px">
            <el-table-column label="姓名" prop="name"/>
            <el-table-column label="性别">
              <template slot-scope="{row}">
                <span>{{ row.gender | filterSex }}</span>
              </template>
            </el-table-column>
            <el-table-column label="手机号" prop="mobile"/>
            <el-table-column label="身份证" prop="idCard"/>
            <el-table-column label="联系邮箱" prop="email"/>
            <el-table-column label="身份">
              <template slot-scope="{row}">
                <div v-if="row.role == 0" class="table-role">指导老师</div>
                <div v-if="row.role == 1" class="table-role">普通成员</div>
                <div v-if="row.role == 2" class="table-role">队长</div>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </ul>
      <div v-else class="empty">
        <img src="../../../assets/img/personal-center/empty-default.png"/>
        <span v-if="openRegistration">暂无报名信息，请点击<span class="btn" @click="handleSignUp">“我要报名”</span>进行报名参赛！</span>
        <span v-else>暂无报名信息</span>
      </div>
    </div>
    <div class="to-study-platform" v-if="openToPlatform" @click="handleStudyPlatform"></div>

    <el-tooltip class="item" effect="dark" :content="tooltipContent" placement="top" v-else>
      <div class="to-study-platform" style="filter: grayscale(100%);"></div>
    </el-tooltip>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {dictByType} from "@/api/sign-up-management"

  export default {
    name: '',
    data() {
      return {
        provinceOptions: [],
        startTime: window._CONFIG['TO_STUDY_START_TIME']
      }
    },
    computed: {
      ...mapState(['openRegistration']),
      ...mapState('personalCenter', ['personalObj', 'signUpList']),
      tooltipContent() {
        return `即将开放此功能，敬请期待`
      },
      // 是否开启跳转学习平台判断
      openToPlatform() {
        const {startTime} = this
        let timeStart = new Date(startTime).getTime()
        let currentTime = new Date().getTime()
        return currentTime > timeStart
      }
    },
    filters: {
      filterSex(val) {
        switch (val) {
          case 0:
            return '保密'
          case 1:
            return '男'
          case 2:
            return '女'
          default:
            return '未知'
        }
      }
    },
    components: {},
    created() {
      this.dictByType();
    },
    methods: {
      async dictByType() {
        let res = await dictByType('geo_province_division');
        if (!res || !res.data) return;
        if (res.data.code != 0) return;
        this.provinceOptions = Object.assign([], res.data.data);
      },
      getProvince(province = '') {
        let obj = this.provinceOptions.find(val => {
          return val.value == province
        })

        if (obj) {
          return obj.label;
        } else {
          return ''
        }
      },
      handleStudyPlatform() {
        // let url = `${window.location.origin}/training`;
        let url = window._CONFIG['TO_STUDY_URL']
        window.open(url, '_blank')
      },
      handleSignUp() {
        this.$router.replace({name: 'sign-up-management'});
      }
    }
  }
</script>

<style scoped lang="scss">
  .personal-team-info {
    /deep/ .el-form-item {
      width: 30%;
    }

    .team-info {
      border-top: 1px solid #D8D8D8;
      padding-top: 25px;
    }

    ul, .empty {
      list-style: none;
      max-height: 470px;
      overflow-y: auto;
      margin-top: 10px;
      li {
        .li-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 48px;
          border-radius: 10px;
          background: linear-gradient(270deg, #FFFFFF 0%, #FFF4EE 45%, #FFFFFF 100%);
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
          font-size: 14px;
          font-weight: 500;
          color: #222222;
          /*box-sizing: border-box;*/
          padding: 0 15px;
        }
      }

      li + li {
        margin-top: 10px;
      }

      .table-role {
        height: 24px;
        width: 80px;
        line-height: 24px;
        text-align: center;
        border-radius: 4px;
        background: #ECF6FF;
        border: 1px solid #D8ECFF;
        color: #004687;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      color: #3D3D3D;
      .btn {
        color: #0084FF;
        cursor: pointer;
      }

    }

    /* 滚动条样式 */
    /deep/
    ul::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 5px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 5px;
      border-radius: 6px;
    }

    /deep/
    ul::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
      /*background: rgba(0, 0, 0, 0.2);*/
      /*background: linear-gradient(180deg, #838C99 0%, rgba(176, 182, 190, 0.2) 100%);*/
      background: #3CA1F3;
    }

    /deep/
    ul::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #E3EEF4;
    }

    /deep/
    ul::-webkit-scrollbar-button {
      display: none;
    }

    .to-study-platform {
      width: 437px;
      height: 127px;
      background: url("../../../assets/img/personal-center/to-study-platform.png") no-repeat center;
      background-size: 100%;
      cursor: pointer;
      margin: 10px auto;
    }
  }
</style>