<template>
  <div class="personal-center">
    <div class="top-bg"></div>
    <personal-content @on-success="getSignUpData"/>

    <!--我要报名-->
    <div class="sign-up-btn" @click="handleSignUp" v-if="openRegistration"></div>
    <!-- 退出 -->
    <div class="logout">
      <img src="../../assets/img/personal-center/logout.png" @click="handleLogout"/>
      <span @click="handleLogout">退出</span>
      <img src="../../assets/img/personal-center/avatar.png" width="30px" height="30px"/>
    </div>
  </div>
</template>

<script>
  import {mapGetters,mapState, mapMutations, mapActions} from 'vuex'
  import PersonalContent from './components/personal-content';
  import {getSignUpData} from '@/api/personal-center'

  export default {
    name: '',
    data() {
      return {
      }
    },
    computed: {
      ...mapState(['openRegistration']),
      ...mapGetters([
        'userInfo' // 登录用户信息
      ])
    },
    components: {
      PersonalContent
    },
    created() {
      this.getSignUpData();
    },
    methods: {
      ...mapMutations("personalCenter", ['mtt_personalObj', 'mtt_signUpList']),
      ...mapActions(['LogOut']),
      async getSignUpData() {
        let id = this.userInfo.id || '';
        if (!id) return;
        let res = await getSignUpData(id);
        if (!res || !res.data) return
        if (res.data.code != 0) return;

        this.mtt_personalObj(Object.assign({}, res.data.data));
        let {signUpList = []} = res.data.data;
        this.mtt_signUpList(signUpList);
      },
      handleSignUp() {
        this.$router.replace({name: 'sign-up-management'})
      },
      handleLogout() {
        this.$confirm("确认退出登录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          await this.LogOut();
          this.$router.replace({path: "/"});
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .personal-center {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #F3FBFF;
    .top-bg {
      width: 100%;
      height: 309px;
      background: url("../../assets/img/personal-center/top-bg.png") no-repeat center;
      background-size: 100%;
    }

    .sign-up-btn {
      position: absolute;
      top: 40px;
      right: 216px;
      width: 135px;
      height: 40px;
      background: url("../../assets/img/personal-center/sign-up-btn.png") no-repeat center;
      cursor: pointer;
    }

    .logout {
      position: absolute;
      top: 40px;
      right: 60px;
      width: 117px;
      height: 40px;
      border-radius: 21.5px;
      opacity: 1;
      background: #FFFFFF;
      /*box-sizing: border-box;*/
      border: 1px solid #FFEFB0;
      box-shadow: 0px 4px 10px 0px rgba(0, 28, 103, 0.5);
      display: flex;
      align-items: center;
      padding: 0 10px 0 17px;
      font-size: 12px;
      font-weight: 500;
      color: #3D3D3D;
      cursor: pointer;
      span {
        margin-right: 12px;
        margin-left: 3px;
      }
    }
  }
</style>